// Misc.
	$misc: (
		z-index-base:				10000
	);

// Duration.
	$duration: (
		menu:						0.5s,
		transition:					0.2s
	);

// Size.
	$size: (
		element-height:				2.75em,
		element-margin:				2em,
		section-spacing:			3em,
		section-spacing-small:		1.5em,
		menu:						25em
	);

// Font.
	$font: (
		family:						('roboto'),
		family-fixed:				('roboto'),
		family-heading:				('roboto'),
		family-link:				('roboto'),
		weight:						400,
		weight-bold:				450,
		weight-heading:				400,
		weight-heading-bold:		450,
		weight-heading-extrabold:	450,
		kerning-heading:			0.25em
	);

// Palette.
	$palette: (
		bg:							#212121,
		bg-alt:						#121212,
		fg:							#ffffff,
		fg-bold:					#ffffff,
		fg-light:					#B3FFFFFF,
		border:						rgb(153, 153, 153),
		border-bg:					rgba(151, 151, 151, 0.623),
		border-alt:					rgba(168, 168, 168, 0.637),
		accent:						#2e59ba
	);
